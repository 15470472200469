import React from 'react'
import { useCart } from './CartContext'
import {
  Typography,
  Button,
  Box,
  Container,
  Card,
  Grid,
  CardContent
} from '@mui/material'
import CartTable from './CartTable'
import { Link } from 'react-router-dom'
const DetailCard = ({ title, content }) => (
  <Card sx={{ backgroundColor: '#fff5e1', margin: 2 }}>
    <CardContent>
      <Typography variant='h6' sx={{ color: '#d5a439', fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant='body2' sx={{ color: '#333' }}>
        {content}
      </Typography>
    </CardContent>
  </Card>
)
const CartPage = () => {
  const { cart, removeFromCart } = useCart()
  const details = [
    {
      title: 'Dispatch Time',
      content: `* It’s 20 working days for dispatching any in house / customised products.
      * Express dispatch’s are available for an additional 350/-. Order will be dispatched in 10 working days. Confirm express dispatch order slot through WA.
      * 10 working days for dispatching any unstitched products.
      * Ready made product will be dispatched in 3 working days.`
    },
    {
      title: 'Tracking & Follow Up',
      content: `Post dispatch, any tracking / follow up will be in WA 7358684536 / 7358083536.`
    },
    {
      title: 'Delivery Time',
      content: `* Post dispatch it’s 2 to 3 business days for delivery within TN.
      * 4 to 6 business days for delivery out of TN.`
    }
  ]
  return (
    <>
      <Container
        maxWidth='lg'
        spacing={2}
        style={{ textAlign: 'center', minHeight: '70vh' }}
      >
        <Box
          style={{
            padding: 10,
            marginTop: 10,
            marginBottom: 10,
            backgroundColor: '#d5a439',
            color: 'white'
          }}
        >
          <Typography variant='h5'>Cart Overview</Typography>
        </Box>
        <Box
          style={{
            width: '100%',
            margin: '0 auto', // Center horizontally
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column' // Ensure content is stacked vertically
          }}
        >
          {cart.length === 0 ? (
            <Typography variant='h5'>Your Cart is Empty!</Typography>
          ) : (
            <CartTable cart={cart} removeFromCart={removeFromCart} />
          )}
        </Box>
        {cart.length === 0 ? (
          <Button
            variant='contained'
            component={Link}
            to='/'
            sx={{ backgroundColor: '#d5a439', marginTop: 10, color: 'white' }}
          >
            Continue Shopping
          </Button>
        ) : (
          <Container>
            <Box display='flex' my={3} justifyContent='space-between'>
              <Button
                variant='contained'
                component={Link}
                to='/'
                sx={{ backgroundColor: '#d5a439', color: 'white' }}
              >
                Continue Shopping
              </Button>
              <Button
                variant='contained'
                component={Link}
                to='/Checkout'
                sx={{ backgroundColor: '#d5a439', color: 'white' }}
              >
                Checkout
              </Button>
            </Box>
          </Container>
        )}
      </Container>

      <Container maxWidth="lg" >
      <Grid container spacing={2}>
        {details.map((detail, index) => (
          <Grid item xs={12} md={4} key={index}>
            <DetailCard title={detail.title} content={detail.content} />
          </Grid>
        ))}
      </Grid>
    </Container>
    </>
  )
}

export default CartPage
