import {
  Table,
  TableBody,
  Container,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
  TextField,
  Box,
  Divider,
  Button,
  Card,
  CardContent
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useEffect, useState } from 'react'
import { useCart } from './CartContext'
import cards from '../Cards.png'
import fetchHeaderMessage from '../fetchHeaderMessage'

const PolicyCard = ({ title, content }) => (
  <Card sx={{ backgroundColor: '#fff5e1' }}>
    <CardContent>
      <Typography variant='h6' sx={{ color: '#d5a439', fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant='body2' sx={{ color: '#333' }}>
        {content}
      </Typography>
    </CardContent>
  </Card>
)

const Checkout = () => {
  const policies = [
    {
      title: 'Exchange',
      content: `Our products are customised according to each client preferences, so exchange won’t be possible for size issues / colour variations.
          • Kindly ensure your size is correct before placing order.
          • Slight colour may vary due to monitor settings.`
    },
    {
      title: 'Return',
      content: `Return can be encouraged if proper proof of damage is provided, like complete video from opening of the parcel without any pause is required.
          To claim return, parcel need to be sent within 2 days also ensure it unused, post return product will be verified for further processing.`
    },
    {
      title: 'Refund',
      content: `No refund.
          Post order confirmation, order won’t be possible for cancellation, hence no refund possible.`
    }
  ]
  const { cart, totalPrice, totalMRP } = useCart()
  const [discountCodes, setDiscountCodes] = useState()

  const getData = async () => {
    if (!discountCodes) {
      // Only fetch data if it hasn't been fetched yet
      const couponCodes = await fetchHeaderMessage()
      const text = couponCodes[1].text
      const discountCodes = text.split(', ').reduce((acc, code) => {
        const [key, value] = code.split(':')
        acc[key] = parseInt(value, 10)
        return acc
      }, {})
      setDiscountCodes(discountCodes)
      cart.forEach(product => {
        console.log(
          `Name: ${product.name}, Selected Size: ${product.selectedSize}, Quantity: ${product.quantity}, Price: ${product.price}`
        )
      })
    }
  }

  useEffect(() => {
    getData()
  }, [discountCodes])
  const [discountCode, setDiscountCode] = useState('')
  const [payableAmount, setPayableAmount] = useState(totalPrice)
  const [discountAmount, setDiscountAmount] = useState(0)

  const applyDiscount = () => {
    const discount = discountCodes[discountCode.toUpperCase()]
    if (discount) {
      const discountAmount = (totalPrice * discount) / 100
      setDiscountAmount(discountAmount)
      setPayableAmount(parseFloat((totalPrice - discountAmount).toFixed(2)))
    } else {
      alert('Invalid discount code')
    }
  }

  return (
    <>
      <Container>
        <Typography variant='h6' sx={{ my: 2 }}>
          Order Summary
        </Typography>
        <TableContainer sx={{ marginBottom: 5 }} component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: 'lightgrey' }}>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Avatar src={item.main_image} alt={item.name} />
                  </TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.selectedSize}</TableCell>
                  {item.discount_price ? (
                    <TableCell>{item.discount_price}</TableCell>
                  ) : (
                    <TableCell>{item.price}</TableCell>
                  )}
                </TableRow>
              ))}
              <TableCell>
                <Typography variant='h6'>Total Price: ₹{totalPrice}</Typography>{' '}
              </TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <Divider />
      <Container maxWidth sx={{ padding: 3 }}>
        <Typography variant='h6'>Contact Information</Typography>
        <Grid container my={2} spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label='Phone Number 1'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label='Phone Number 2'
              variant='outlined'
            />
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container maxWidth sx={{ padding: 3 }}>
        <Typography variant='h6' sx={{ marginBottom: 2 }}>
          Shipping Address
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ alignContent: 'center' }}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label='First Name'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label='Last Name'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label='Email Address'
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid container mt={4} spacing={2}>
          <Grid item xs={12} md={6} sx={{ alignContent: 'center' }}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label='Address Line 1'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label='Address Line 2'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label='Select State'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label='Enter City'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label='Postal Code'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              disabled
              label='India'
              variant='outlined'
            />
          </Grid>
        </Grid>
      </Container>
      <Container
        sx={{
          width: { xs: '100%', md: '40%' },
          border: '2px solid lightgrey',
          borderRadius: 4,
          padding: 3
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography fontWeight={700} variant='h6'>
            Cart Total
          </Typography>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}
        >
          <Typography>MRP</Typography>
          <Typography sx={{ textDecoration: 'line-through' }}>
            ₹{totalMRP}
          </Typography>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}
        >
          <Typography>Your Price</Typography>
          <Typography>₹{totalPrice}</Typography>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}
        >
          <Typography>Shipping Charges</Typography>
          <Typography>₹0</Typography>
        </Box>
        <Divider />
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}
        >
          <Typography sx={{ fontWeight: 700 }}>Total</Typography>
          <Typography>₹{totalPrice}</Typography>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}
        >
          <Typography sx={{ fontWeight: 700 }}>Coupon Discount</Typography>
          <Typography>-₹{discountAmount}</Typography>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}
        >
          <TextField
            label='Discount Code'
            value={discountCode}
            onChange={e => setDiscountCode(e.target.value)}
          />
          <Button variant='contained' onClick={applyDiscount}>
            Apply
          </Button>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}
        >
          <Typography sx={{ fontWeight: 700 }}>Payable Amount:</Typography>
          <Typography>₹{payableAmount}</Typography>
        </Box>
        <img src={cards} alt='Course' width='100%' style={{ marginTop: 3 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            sx={{ backgroundColor: '#d5a439', color: 'white' }}
          >
            Complete Payement
          </Button>{' '}
        </Box>
      </Container>
      <Container maxWidth='lg' sx={{ my: 3 }}>
        <Grid container spacing={2}>
          {policies.map((policy, index) => (
            <Grid item xs={12} md={4} key={index}>
              <PolicyCard title={policy.title} content={policy.content} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}
export default Checkout
