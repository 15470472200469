import React, { useEffect } from 'react'
import {
  Typography,
  Container,
  Card,
  CardContent,
  Box,
  Grid,
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import PaymentIcon from '@mui/icons-material/Payment'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import FavoriteIcon from '@mui/icons-material/Favorite'
import '../App.css'

const FooterCard = ({ icon: Icon, text }) => (
  <Card sx={{ backgroundColor: '#fcdedf', textAlign: 'center', minWidth: 200 }}>
    <CardContent>
      <Icon sx={{ fontSize: 40, color: '#d5a439' }} />
      <Typography variant='body1' sx={{ color: '#333', marginTop: 1 }}>
        {text}
      </Typography>
    </CardContent>
  </Card>
)

const MyComponent = () => {
  const location = useLocation()
  const whatsappLogo =
    'https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg'
  const instagramLogo =
    'https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png'

  const contacts = [
    { type: 'WhatsApp', url: 'https://wa.me/7358684536', label: 'WhatsApp Support 1' },
    { type: 'WhatsApp', url: 'https://wa.me/7358083536', label: 'WhatsApp Support 2' },
    {
      type: 'Instagram',
      url: 'https://www.instagram.com/chirapathi.thelabel?igsh=Y3lxYmNhdzdmMW5h',
      label: 'Chirapathi.thelabel '
    },
    {
      type: 'Instagram',
      url: 'https://www.instagram.com/chirapathi.aari_couture?igsh=eHJkM2JlejI5NDUw',
      label: 'Chirapathi.aari_couture'
    },
    {
      type: 'Instagram',
      url: 'https://www.instagram.com/chirapathi.sarees?igsh=MThxNnpjYTR3ZWU5bQ==',
      label: 'Chirapathi.Sarees'
    },
    {
      type: 'Instagram',
      url: 'https://www.instagram.com/vaia.bychirapathi?igsh=a2lndXQ3a3Fmb284',
      label: 'Vaia.byChirapathi'
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])


  const footerItems = [
    { icon: PaymentIcon, text: 'Secure Payment' },
    { icon: LocalOfferIcon, text: 'Best Price' },
    { icon: FavoriteIcon, text: 'Handicrafted with love' },
  ]

  return (
    <>
      <Container
        display='flex'
        justifyContent='space-between'
        maxWidth
        disableGutters
        sx={{ marginTop: 1 }} // Ensure the container takes the full height of the viewport
      >
        <Box
          className='scroll-container'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          {footerItems.map((item, index) => (
            <Box className='scroll-item' key={index} sx={{ flex: '1 0 auto' }}>
              <FooterCard icon={item.icon} text={item.text} />
            </Box>
          ))}
        </Box>
      </Container>

      <Container maxWidth sx={{ marginTop: 2 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                backgroundColor: '#fcdedf',
                padding: 2,
                textAlign: 'left',
                height: '100%'
              }}
            >
              <Typography color='#ad0002' variant='h6'>
                Footer Menu
              </Typography>
              <Link
                to='/AboutUs'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Typography variant='body1'>About Us</Typography>
              </Link>
              <Link
                to='./DispatchDelivery'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Typography variant='body1'>Dispatch and Delivery</Typography>
              </Link>
              <Link
                to='/ReturnRefundExchange'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Typography variant='body1'>
                  Return, Refund & Exchange
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                backgroundColor: '#fcdedf',
                padding: 2,
                textAlign: 'left',
                height: '100%'
              }}
            >
              <Typography color='#ad0002' variant='h6'>
                Quick Links
              </Typography>
              <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='body1'>Home</Typography>
              </Link>
              <Link
                to='/OfflineStore'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Typography variant='body1'>Offline Store</Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                backgroundColor: '#fcdedf',
                padding: 2,
                textAlign: 'left',
                height: '100%',
                display: 'flex'
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#fcdedf',
                  textAlign: 'left',
                  width:'100vh'
                }}
              >
                <Typography color='#ad0002' variant='h6' gutterBottom>
                  Get In Touch
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ flex: 1 }}>
                    {contacts
                      .filter(contact => contact.type === 'WhatsApp')
                      .map((contact, index) => (
                        <Link
                          key={index}
                          to={contact.url}
                          target='_blank'
                          style={{
                            color: 'inherit',
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 8
                          }}
                        >
                          <img
                            src={whatsappLogo}
                            alt='WhatsApp'
                            style={{ width: 20, height: 20, marginRight: 8 }}
                          />
                          <Typography variant='body1' component='span'>
                            {contact.label}
                          </Typography>
                        </Link>
                      ))}
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    {contacts
                      .filter(contact => contact.type === 'Instagram')
                      .map((contact, index) => (
                        <Link
                          key={index}
                          to={contact.url}
                          target='_blank'
                          style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 8
                          }}
                        >
                          <img
                            src={instagramLogo}
                            alt='Instagram'
                            style={{ width: 20, height: 20, marginRight: 8 }}
                          />
                          <Typography variant='body1' component='span'>
                            {contact.label}
                          </Typography>
                        </Link>
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default MyComponent
