import { useNavigate, useParams } from 'react-router-dom'
import fetchProducts from '../fetchProducts'
import { useState, useEffect, useCallback } from 'react'
import { useCart } from './CartContext'
import { memo, Suspense } from 'react'
import {
  Card,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Button,
  Box,
  Container,
  Skeleton,
  ButtonGroup
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { debounce } from 'lodash'
import React from 'react'

const ImageCarousel = React.lazy(() => import('./ImageCarousel')) // Lazy-load the ImageCarousel component

const ProductInfo = () => {
  const { product_id } = useParams()
  const [productInfo, setProductInfo] = useState(null)
  const [imageArray, setImageArray] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [selectedSize, setSelectedSize] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { cart, addToCart } = useCart()
  const navigate = useNavigate()
  const id = product_id

  // Caching with TTL (24 hours)
  const getProducts = useCallback(async () => {
    const cachedProducts = localStorage.getItem('products')
    const cachedTime = localStorage.getItem('cacheTime')
    const now = Date.now()
    let productsList

    if (cachedProducts && cachedTime && now - cachedTime < 86400000) {
      productsList = JSON.parse(cachedProducts)
    } else {
      productsList = await fetchProducts()
      localStorage.setItem('products', JSON.stringify(productsList))
      localStorage.setItem('cacheTime', now)
    }

    const product = productsList.find(item => item.product_id == id)
    if (product) {
      setProductInfo(product)
      setImageArray(product.images)
    } else {
      console.warn(`Product with id ${id} not found`)
    }
  }, [id])

  const debouncedGetProducts = useCallback(debounce(getProducts, 300), [
    getProducts
  ])

  useEffect(() => {
    debouncedGetProducts()
    return () => debouncedGetProducts.cancel()
  }, [debouncedGetProducts])

  const handleSizeChange = event => setSelectedSize(event.target.value)

  const handleAddToCart = () => {
    if (productInfo.size && !selectedSize) {
      setErrorMessage('Please select a size.')
    } else {
      setErrorMessage('')
      addToCart(productInfo, selectedSize, quantity)
    }
  }

  const handleBuyNow = () => {
    if (productInfo.size && !selectedSize) {
      setErrorMessage('Please select a size.')
    } else {
      setErrorMessage('')
      if (cart.length === 0) addToCart(productInfo, selectedSize, quantity)
      navigate('/Checkout')
    }
  }

  if (!productInfo) {
    return (
      <Container maxWidth='md' disableGutters sx={{ height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            height: '100%'
          }}
        >
          <Box flex={1}>
            <Skeleton variant='rectangular' width='100%' height={300} />
          </Box>
          <Box flex={1}>
            <Card>
              <CardContent>
                <Skeleton variant='text' width='100%' />
                <Skeleton variant='text' width='60%' />
                <Skeleton variant='text' width='80%' />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    )
  }

  return (
    <Container maxWidth='md' disableGutters sx={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          height: '100%'
        }}
      >
        <Box flex={1}>
          <Suspense
            fallback={
              <Skeleton variant='rectangular' width='100%' height={300} />
            }
          >
            <ImageCarousel images={imageArray} />
          </Suspense>
        </Box>
        <Box flex={1}>
          <Card>
            <CardContent>
              <Typography
                variant='h5'
                component='div'
                style={{ textTransform: 'uppercase' }}
              >
                {productInfo.name}
              </Typography>
              <Typography color='text.secondary'>
                {productInfo.description.split('|').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
              {productInfo.description1 && (
                <Typography color='text.secondary'>
                  {productInfo.description1.split('|').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              )}

              <Typography variant='h6' component='div'>
                ₹{productInfo.discount_price || productInfo.price}
              </Typography>
              {productInfo.discount_price && (
                <Box display='flex'>
                  <Typography variant='h8' color='red' sx={{ marginRight: 1 }}>
                    -
                    {(
                      ((productInfo.price - productInfo.discount_price) /
                        productInfo.price) *
                      100
                    ).toFixed(2)}
                    % Off
                  </Typography>
                  <Typography
                    variant='h8'
                    component='div'
                    sx={{ marginLeft: 1, textDecoration: 'line-through' }}
                  >
                    ₹{productInfo.price}
                  </Typography>
                </Box>
              )}

              <Box sx={{ marginTop: '2vh', marginBottom:'2vh' }}>
                <Typography>Quantity: {quantity}</Typography>
                <ButtonGroup>
                  <Button
                    onClick={() => setQuantity(Math.max(quantity - 1, 1))}
                  >
                    <RemoveIcon fontSize='small' />
                  </Button>
                  <Button onClick={() => setQuantity(quantity + 1)}>
                    <AddIcon fontSize='small' />
                  </Button>
                </ButtonGroup>
              </Box>
              {productInfo.size && (
                <FormControl component='fieldset' margin='normal'>
                  <RadioGroup
                    row
                    value={selectedSize}
                    onChange={handleSizeChange}
                  >
                    {productInfo.size.split(',').map(size => (
                      <FormControlLabel
                        key={size}
                        value={size.trim()}
                        control={<Radio />}
                        label={size.trim()}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}

              <Box display='flex' sx={{ justifyContent: 'space-between' }}>
                <Button
                  variant='contained'
                  sx={{ backgroundColor: '#d5a439', width: '40%' }}
                  onClick={handleAddToCart}
                >
                  Add to Cart
                </Button>
                <Button
                  variant='contained'
                  sx={{ backgroundColor: '#d5a439', width: '40%' }}
                  onClick={handleBuyNow}
                >
                  Buy Now
                </Button>
              </Box>
              {errorMessage && (
                <Typography variant='body1' color='error'>
                  {errorMessage}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Container>
  )
}

export default memo(ProductInfo)
