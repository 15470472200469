import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  IconButton,
  Typography
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useCart } from './CartContext'

const CartTable = ({ cart, removeFromCart }) => {
  const { totalPrice } = useCart()
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ backgroundColor: 'lightgrey' }}>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Price</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cart.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <Avatar src={item.main_image} alt={item.name} />
              </TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.selectedSize}</TableCell>
              <TableCell>{item.discount_price}</TableCell>
              <TableCell>
                <IconButton onClick={() => removeFromCart(index)} color='error'>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3} align='right'>
              <Typography variant='h5'>Total:</Typography>
            </TableCell>
            <TableCell>{totalPrice}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CartTable
