import React from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';

const Root = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fff5e1', // Light yellow background
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#d32f2f', // Red color for the title
  fontWeight: 'bold',
}));

const Content = styled(Typography)(({ theme }) => ({
  color: '#424242', // Dark grey for the content
  lineHeight: 1.8,
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#ffffff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for a professional look
}));

const CustomList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  fontWeight: 800, // Increased font weight
}));

const DispatchDelivery = () => {
  return (
    <Root maxWidth>
      <CustomPaper elevation={3}>
        <Title variant="h4">
          Dispatch and Delivery Details
        </Title>
        <CustomList>
          <ListItem>
            <CustomListItemText primary="• It’s 20 working days for dispatching any in-house/customised products." />
          </ListItem>
          <ListItem>
            <CustomListItemText primary="• Express dispatches are available for an additional ₹350. Orders will be dispatched in 10 working days. Confirm express dispatch order slot through WhatsApp." />
          </ListItem>
          <ListItem>
            <CustomListItemText primary="• 10 working days for dispatching any unstitched products." />
          </ListItem>
          <ListItem>
            <CustomListItemText primary="• Ready-made products will be dispatched in 3 working days." />
          </ListItem>
        </CustomList>
        <Content variant="body1">
          Post dispatch, any tracking/follow-up will be handled through WhatsApp at 7358684536 / 7358083536.
        </Content>
        <Content variant="body1">
          Post dispatch, it’s 2 to 3 business days for delivery within TN and 4 to 6 business days for delivery outside TN.
        </Content>
      </CustomPaper>
    </Root>
  );
};

export default DispatchDelivery;
