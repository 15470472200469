import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';

const Root = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fff5e1'
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#ad0002', // Red color for the title
  fontWeight: 'bold',
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: '#f57c00', // Dark yellow color for the subtitle
}));

const Content = styled(Typography)(({ theme }) => ({
  color: '#424242', // Dark grey for the content
  lineHeight: 1.8,
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#ffffff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for a professional look
}));

const AboutUs = () => {
  return (
    <Root maxWidth>
      <CustomPaper elevation={3}>
        <Title variant="h4">
          About Us
        </Title>
        <Subtitle variant="h6">
          Rekha Anand is the founder of CHIRAPATHI, a brand that beautifully blends tradition with modernity.
        </Subtitle>
        <Content variant="body1">
          With qualifications in Corporate Secretaryship (BCom) and Fashion Designing (DFD), Rekha’s passion for both business and fashion inspired the creation of CHIRAPATHI, a boutique brand that celebrates the elegance of handcrafted designs.
        </Content>
        <Content variant="body1">
          At CHIRAPATHI, we focus on customization, offering our clients designs that are not only stylish but also deeply personal, tailored to fit each individual’s unique tastes and style. We specialize in exquisite aari work, bringing timeless craftsmanship into contemporary fashion. Our collections feature intricately embroidered pieces perfect for every occasion, from casual elegance to stunning bridal ensembles.
        </Content>
        <Content variant="body1">
          Our signature sarees pay tribute to India’s rich cultural heritage, blending traditional artistry with modern aesthetics. Whether it’s bespoke bridal sarees or everyday drapes, each piece is crafted with care to reflect beauty and individuality.
        </Content>
      </CustomPaper>
    </Root>
  );
};

export default AboutUs;
