import {
  Container,
  Typography,
  Card,
  CardContent,
  useMediaQuery
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import fetchProducts from '../fetchProducts'
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

const CuratedProducts = () => {
  const [products, setProducts] = useState([])

  const getProducts = async () => {
    // Check if products are already cached
    const cachedProducts = localStorage.getItem('products')
    if (cachedProducts) {
      setProducts(JSON.parse(cachedProducts))
    } else {
      // Fetch products if not cached
      const productsList = await fetchProducts()
      setProducts(productsList)
      // Cache the fetched products
      localStorage.setItem('products', JSON.stringify(productsList))
    }
  }

  useEffect(() => {
    getProducts()
  }, [])

  const filteredProducts = products.filter(product => product.category === 'Party Wear')

  /////////////////////
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  /////////////////////

  return (
    <Container maxWidth style={{ padding: 5, marginTop: '3vh' }}>
      <Box my={3} sx={{ flexGrow: 1 }}>
        {Array.from(Array(1)).map((_, index) => (
          <Grid
            container
            spacing={1}
            key={index}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {filteredProducts.map((product, index) => (
              <Grid key={index} size={{ xs: 2, lg: 3 }}>
                <Card
                  elevation={0}
                  style={{
                    width: 'fixed',
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <NavLink
                    to={`/info/${product.product_id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <img
                      src={product.main_image}
                      alt='Course'
                      width='100%'
                      style={{ marginTop: 3 }}
                    />
                    <CardContent>
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        textAlign='center'
                        color='#d5a439'
                        style={{
                          textTransform: 'uppercase',
                          letterSpacing: '0.5px'
                        }}
                      >
                        {product.name}
                      </Typography>

                      {product.discount_price ? (
                        <>
                          <Typography
                            variant='body1'
                            textAlign='center'
                            style={{ textDecoration: 'line-through' }}
                            color='#d5a439'
                          >
                            ₹ {product.price}
                          </Typography>
                          <Typography
                            variant='body1'
                            textAlign='center'
                            color='#d5a439'
                          >
                            ₹{product.discount_price}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          variant='body1'
                          textAlign='center'
                          color='#d5a439'
                        >
                          ₹ {product.price}
                        </Typography>
                      )}
                    </CardContent>
                  </NavLink>
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
      </Box>
    </Container>
  )
}

export default CuratedProducts
