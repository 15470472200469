import React from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';

const Root = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fff5e1', // Light yellow background
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#d32f2f', // Red color for the title
  fontWeight: 'bold',
}));

const Content = styled(Typography)(({ theme }) => ({
  color: '#424242', // Dark grey for the content
  lineHeight: 1.8,
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#ffffff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for a professional look
}));

const CustomList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  fontWeight: 800, // Increased font weight
}));

const ReturnRefundExchange = () => {
  return (
    <Root>
      <CustomPaper elevation={3}>
        <Title variant="h4">
          Exchange / Return / Refund
        </Title>
        <Content variant="body1">
          Our products are customised according to each client's preferences, so exchange won’t be possible for size issues or colour variations.
        </Content>
        <CustomList>
          <ListItem>
            <CustomListItemText primary="• Kindly ensure your size is correct before placing order." />
          </ListItem>
          <ListItem>
            <CustomListItemText primary="• Slight colour may vary due to monitor settings." />
          </ListItem>
        </CustomList>
        <Content variant="body1">
          Return can be encouraged if proper proof of damage is provided, like a complete video from opening of the parcel without any pause is required. To claim return, parcel needs to be sent within 2 days and ensure it is unused. Post return, the product will be verified for further processing.
        </Content>
        <Content variant="body1">
          No refund. Post order confirmation, order won’t be possible for cancellation, hence no refund possible.
        </Content>
      </CustomPaper>
    </Root>
  );
};

export default ReturnRefundExchange;
