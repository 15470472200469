import { collection, getDocs } from 'firebase/firestore'
import { db } from './firebaseConfig'

const fetchProducts = async () => {
  const productsCollection = collection(db, 'products')
  const productSnapshot = await getDocs(productsCollection)
  const productList = productSnapshot.docs.map(doc => doc.data())
  return productList
}

export default fetchProducts
