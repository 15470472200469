import React, { createContext, useContext, useState } from 'react'

const CartContext = createContext()

export const useCart = () => useContext(CartContext)

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([])

  const addToCart = (productInfo, selectedSize, quantity) => {
    setCart([...cart, { ...productInfo, selectedSize, quantity }])
  }
  const removeFromCart = index => {
    setCart(cart.filter((_, i) => i !== index))
  }
  const totalPrice = cart.reduce(
    (total, item) => total + item.discount_price * item.quantity,
    0
  )
  const totalMRP = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  )
  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, totalPrice, totalMRP }}>
      {children}
    </CartContext.Provider>
  )
}
