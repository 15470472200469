import { collection, getDocs } from 'firebase/firestore'
import { db } from './firebaseConfig'


const fetchHeaderMessage = async () =>{
    const headerMessageCollection = collection(db,'header_message')
    const headerMessageSnapshot = await getDocs(headerMessageCollection)
    const headerMessage = headerMessageSnapshot.docs.map(doc => doc.data() )
    return headerMessage
}
export default fetchHeaderMessage
