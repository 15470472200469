import { Container, Typography } from '@mui/material'
import fetchHeaderMessage from '../fetchHeaderMessage'
import { useEffect, useState } from 'react'

const Header = () => {
  const [data, setData] = useState(null)
  const getData = async () => {
    if (!data) { // Only fetch data if it hasn't been fetched yet
      const headerMessage = await fetchHeaderMessage()
      setData(headerMessage[0])
    }
  }
  useEffect(() => {
    getData()
  }, [data]) // Add data as a dependency to avoid unnecessary calls

  return (
    <Container
      maxWidth
      disableGutters
      style={{
        height: '4vh',
        alignContent: 'center',
        background: '#d5a439',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }}
    >
      <Typography
        style={{
          display: 'inline-block',
          color: 'white',
          animation: 'scroll 30s linear infinite'
        }}
      >
        {data ? data.text : 'Loading...'}
      </Typography>
      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </Container>
  )
}

export default Header
