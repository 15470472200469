import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBwvHcJTXrqeknFeSNJMHsqAM27jvDfaWc',
  authDomain: 'chirapathi-2b786.firebaseapp.com',
  projectId: 'chirapathi-2b786',
  storageBucket: 'chirapathi-2b786.appspot.com',
  messagingSenderId: '543548603104',
  appId: '1:543548603104:web:a09405eee69fef11df6e85'
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { db }
