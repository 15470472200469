import React from 'react'
import { Container, Typography, Box } from '@mui/material'
import StoreIcon from '@mui/icons-material/Store'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'

const OfflineStore = () => {
  return (
    <Container maxWidth='sm' style={{ textAlign: 'center', marginTop: '20px' }}>
      <StoreIcon style={{ fontSize: '100px', color: '#d5a439' }} />

      <Typography
        variant='h4'
        component='h1'
        gutterBottom
        style={{ fontWeight: '600' }}
      >
        Offline Store by Chirapathi
      </Typography>
      <Typography
        variant='body1'
        component='p'
        gutterBottom
        style={{ fontWeight: '600' }}
      >
        We have segregation of online and offline collections
      </Typography>
      <Typography
        variant='body1'
        component='p'
        gutterBottom
        style={{ fontWeight: '600' }}
      >
        online products will not be available at offline store since it will be
        dispatched directly from production unit{' '}
      </Typography>
      <Typography
        variant='body1'
        component='p'
        style={{
          marginTop: '20px',
          fontSize: '18px',
          lineHeight: '1.6',
          fontWeight: '600'
        }}
      >
        Offline is for customisation with your own fabric and bridal works
      </Typography>
      <Typography
        variant='body1'
        component='p'
        style={{
          marginTop: '10px',
          fontSize: '18px',
          lineHeight: '1.6',
          fontWeight: '600'
        }}
      >
        <PhoneIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
        Visit us with appointments <br /> contact +91 7358083536
      </Typography>
      <Typography
        variant='body1'
        component='p'
        style={{
          marginTop: '10px',
          fontSize: '18px',
          lineHeight: '1.6',
          fontWeight: '600'
        }}
      >
        <LocationOnIcon style={{ verticalAlign: 'middle' }} />
        Address
        <br />
        Chirapathi
        <br />
        No - 25/49, Josier Street,
        <br />
        Thirumurthy Nagar Nungambakkam,
        <br />
        Chennai - 600034
      </Typography>
    </Container>
  )
}

export default OfflineStore
